import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActionsService } from '@app/services';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';

declare var $: any;


@Component({
	selector: 'app-actions-editor',
	templateUrl: './actions-editor.component.html',
	styleUrls: ['./actions-editor.component.scss']
})
export class ActionsEditorComponent implements OnInit {

	errorMessage: string = ''
	startnameval: string = '';
	buttondisable: boolean = true;
	act_tab = 0;
	del_flag: boolean = false;
	obj: any = {};
	editorOptions = { theme: 'vs-dark', language: '', automaticLayout: true };
	code: string = 'function x() { // TODO }';
	code1: string;
	editor: any;
	action_id: any;
	actionData: any;
	tabs: any[] = [];
	active_index: any = 0;
	value: any;
	file_name: string = '';
	loading: boolean = false;
	editorData: any[] = [];
	main_file: boolean = false;
	tab_index: any = 0;
	deleteFileId: any = null;
	// originalCode: string = 'function x() { // TODO }'

	@ViewChild('editor') editorSection: any;

	showEditor = false;
	saveEnableList: any = [];
	// originalCode: string = 'function x() { // TODO }';
	constructor(
		private location: Location,
		private actionService: ActionsService,
		private route: ActivatedRoute,
		private ts: ToasterService
	) {
		this.action_id = this.route.snapshot.params.action_id;
		this.actionService.getActionDetails(this.action_id).subscribe((data: any) => {
			this.actionData = data;
			this.editorOptions['language'] = data.type_in_word.toLowerCase();
			this.getEditorData(this.action_id);
		});
	}

	ngOnInit() {
		this.showEditor = true;
		this.active_index = 0;
	}

	onInit(editor, idx, tab) {
		this.showEditor = true;
		this.editor = editor;
		if (tab && tab.hasOwnProperty('editor')) {
			this.editor.setValue(tab['editor'])
		} else {
			this.editor.setValue(this.editor.getId());
		}

		this.value = this.editor.getValue();
		// if(this.active_index == 0) {
		this.tabs[idx]['editor'] = this.editor.getValue();
		// }
		editor.onDidChangeModelContent(e => {
			this.editor = editor;
			// console.log('editor',editor.getValue(),this.tabs[idx]['name'])
			this.saveEnableList.indexOf(idx) === -1 && this.saveEnableList.push(idx);
			this.obj[this.tabs[idx]['name']] = editor.getValue();
			// editor.getValue() => will get value when type in a editor(stream)
		});
	}

	// to navigate to previous page 
	goBack() {
		this.location.back();
	}

	// on editor data save 
	onSave(tab, i) {
		this.errorMessage = '';
		this.startnameval = tab.name;
		// TODO: Disable save button if no changes in content this will save from ton of issues

		// this will contain old data before  saving
		// this.editor = tab['editor'].
		// console.log(this.editor.getValue());
		const fileId = tab.id;
		this.file_name = tab.file_name;
		this.main_file = tab.main_file;
		this.tab_index = i;
		// console.log("save",this.tab_index)
		// this.removeExistingMainFile(fileId)
		$('#fileNameModal').modal('show');
	}

	onClick() {
		// let id = this.tabs[this.tabs.length - 1].id + 1;
		try {
			if (this.tabs[this.tabs.length - 1]['name'].indexOf('File') > -1) {
				let i = parseInt(this.tabs[this.tabs.length - 1]['name'].replace('File', '')) + 1;
				this.tabs.push({ id: 'file' + i, name: 'File' + i, file_name: 'File' + i, main_file: false });
			} else {
				this.tabs.push({ id: 'file1', name: 'File1', file_name: 'File1', main_file: false });
			}
			this.obj[(this.tabs[Number(Object.keys(this.tabs)[Object.keys(this.tabs).length - 1]) - 1])["name"]] = this.editor.getValue();
		}
		catch (err) {
			this.tabs.push({ id: 'file1', name: 'File1', file_name: 'File1', main_file: false });
		}
		// console.log("valueeeeee",(this.tabs[Number(Object.keys(this.tabs)[Object.keys(this.tabs).length-1])-1])["name"] ,this.editor.getValue());

		this.onChange(this.tabs.length - 1)
	}

	onChange(idx, tab?: any) {
		this.showEditor = true;
		this.active_index = idx;
		// console.log(this.active_index)
		if (tab && tab.hasOwnProperty('editor')) {
			this.editor.setValue(tab['editor'])
		}
		this.del_flag = false;
	}


	onNameChange(nameval) {
		this.errorMessage = '';
		let errorMessages = " The Name already exists";
		this.buttondisable = true;
		if (this.startnameval != nameval) {
			let ispresentflag = this.tabs.find(o => o.name === nameval);
			if (typeof ispresentflag != "undefined") {
				this.buttondisable = false;
				this.errorMessage = errorMessages;
			}
			ispresentflag = {};
			delete this.obj[nameval];

		}
		// this.buttondisable=true;


	}

	// to get file name 
	getFileName() {
		this.loading = true;
		this.buttondisable = true;
		// this.file_name = $('input[name="file_name"]').val();
		// console.log('actionId',this.action_id)

		// console.log("filesave",this.tabs,this.file_name,this.obj)
		// console.log("names",this.tabs.map(({ name }) => name))

		let val = { 'file_name': this.file_name, data: this.editor.getValue(), 'main_file': this.main_file }
		this.actionService.saveEditorData(this.action_id, val).subscribe(data => {
			this.saveEnableList = this.saveEnableList.filter(x => x !== this.tab_index);
			this.loading = false;
			this.ts.pop('success', 'Editor data saved');
			$('#fileNameModal').modal('hide');
			const isMainFile = data['data'].is_main_file;
			// console.log("all",data)
			// this.removeExistingMainFileInUI(isMainFile, data);Object.keys(this.obj)
			// console.log("ss",Object.keys(this.obj),data['data'].id)

			let temp = Object.keys(this.obj).map(name => name.toLowerCase());

			console.log("dd", temp, this.obj);
			// console.log("ed",this.editor.getValue());

			if (Object.keys(this.obj).includes(data['data'].name)) {
				console.log(this.obj[data['data'].name], this.editor.getValue())


				this.tabs[this.tab_index] = {
					id: data['data'].id, name: data['data'].name, editor: this.obj[data['data'].name],
					file_name: data['data'].name, main_file: data['data'].is_main_file
				};
				delete this.obj[data['data'].name];
			}
			else {

				// 	this.tabs[this.tab_index] = {id: data['data'].id, name: data['data'].name, editor: this.editor.getValue(),
				// 							file_name:data['data'].name, main_file:data['data'].is_main_file}
				this.tabs[this.tab_index] = {
					id: data['data'].id, name: data['data'].name, editor: data['data'].file_content,
					file_name: data['data'].name, main_file: data['data'].is_main_file
				}
			}



			// console.log("all",data)
			this.removeExistingMainFileInUI(isMainFile, data);
			if (data['data'].is_main_file) {
				this.removeExistingMainFile(data['data'].id) // at backend level
			}
		}, error => {
			$('#fileNameModal').modal('hide');
			this.loading = false;
			this.ts.pop('error', error.detail)
		})
		this.del_flag = false;
	}

	private removeExistingMainFileInUI(isMainFile: boolean, data: Object) {
		if (isMainFile) {
			const currentFileIndex = this.tabs.findIndex(file => file.id === data['data'].id);
			if (currentFileIndex >= 0) {
				this.tabs.map((file, otherFileIndex) => {
					if (otherFileIndex != currentFileIndex) {
						file['main_file'] = false;
					}
				});
			}
		}
	}

	// file name modal close 
	closeModal() {
		$('#fileNameModal').modal('hide');
		this.startnameval = "";
	}

	onEnterFileName(event) {
		this.file_name = event.target.value;
	}

	// to get existing editor data 
	getEditorData(action_id) {
		this.tabs = [];
		this.actionService.getActionEditorData(action_id).subscribe(response => {
			if (response && response['data'].length > 0) {
				this.editorData = response['data'];
				response['data'].forEach(element => {
					this.tabs.push({ id: element.id, 'name': element.name, 'editor': element.file_content, 'file_name': element.name, 'main_file': element.is_main_file });
				});
			} else {
				this.tabs = [{ id: 'file1', 'name': 'File1', file_name: '', main_file: false }]
			}
			this.showEditor = true;
			this.active_index = 0;
		})
	}

	deleteEditorFile(fileId: number) {
		this.actionService.deleteEditorFile(fileId).subscribe(response => {
			if (response && response['data'].length > 0) {
				$('#editor-delete-modal').modal('hide');
				this.ts.pop('success', 'Editor Data Deleted')
				// console.log("del",this.tabs,(this.tabs).length-1,this.active_index)
				this.del_flag = true;
				this.tabs = this.tabs.filter((item) => item.id !== fileId);//to delete in frontend side
				this.act_tab = this.tabs.length - 1;
				this.active_index = 1;
				this.tab_index = 1;
				// console.log("len",this.act_tab)
				// this.getEditorData(this.action_id)
			}
		}, error => {
			this.ts.pop('error', error.detail)
		})
	}

	removeExistingMainFile(fileId: number) {
		this.actionService.removeExistingMainFile(this.action_id, fileId).subscribe(response => {
			if (response && response['data'].length > 0) {
				return true
			}
		}, error => {
			this.ts.pop('error', error.detail)
		})
	}

	openDeleteModal(fileId) {
		this.deleteFileId = fileId;
		$('#editor-delete-modal').modal('show');
	}

	closeDeleteModal() {
		$('#editor-delete-modal').modal('hide');
		this.deleteFileId = null;
	}

	isNumberType(value: any) {
		return typeof (value) === 'number'
	}

	handleDeleteModal() {
		// uncomment this code only if default file also needs to be deleted
		// if (! this.isNumberType(this.deleteFileId)){
		// 	const fileTobeDeleted:object = this.tabs.find(({id})=>id===this.deleteFileId)
		// 	const fileIndex = this.tabs.indexOf(fileTobeDeleted)
		// 	this.tabs.splice(fileIndex,1)
		// 	$('#editor-delete-modal').modal('hide');
		// 	this.ts.pop('success','Deleted Successfully')
		// }
		this.deleteEditorFile(this.deleteFileId);
	}

}
