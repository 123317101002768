import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionsService } from '@app/services';
import { Config } from '@app/shared/directives/custom-datatable/interface';
import { ToasterService } from 'angular2-toaster';
import { from } from 'rxjs';
import { actionAttributes, actionIOType } from 'src/app/core/constants/constants';
import { Location } from '@angular/common';
import { isObjectsEqual } from '@app/utils/utils';
import { environment } from 'src/environments/environment';
import { SettingsService } from '@app/core';

declare var $: any;

@Component({
	selector: 'app-input-form-editor',
	templateUrl: './input-form-editor.component.html',
	styleUrls: ['./input-form-editor.component.scss']
})
export class InputFormEditorComponent implements OnInit {

	baseUrl = environment.baseUrl
	inputForm: FormGroup;
	options: any[] = [{ id: 1, text: 'Yes' }, { id: 2, text: 'No' }];
	projectOptions: any[];
	environmentOptions: any[];
	categories: any[] = [];
	environments: any[] = [];
	projects: any[] = [];
	columns: any[] = [];
	action_id: any;
	mode: string = 'Add';
	checkout_selected: any[] = [];
	environment_selected: any[] = [];
	project_selected: any[] = [];
	actionInputForm: FormGroup;
	outputForm: FormGroup;
	enable: boolean = false;
	hidden_value_boolean: boolean = false;

	config: Config = {
		screenName: '',
		remoteUrl: '',
		remoteParams: {},
		pageSize: 10,
		tableButton: { text: 'Add Project', show: false, access: [], icon: 'fa fa-plus' },
		removeTableBorder: true,
		tableClass: 'table-custom-css-project',
		hidePagination: false
	};
	type_options: any[] = actionIOType;
	action_attribute_options: any[] = actionAttributes;
	io_options: any[] = [
		{ id: 1, text: 'Yes' },
		{ id: 2, text: 'No' }
	];
	hidden_value_options: any = [{ id: -1, text: 'Allow any' },
	{ id: 1, text: 'Specific value' }];
	selected_type: any[] = [];
	selected_attribute: any[] = [];
	fields: any[] = [];
	activeCondition: any[] = [];
	input_data: any;
	multiple: any[] = [];
	required: any[] = [];
	dynamic_input: any[] = [];
	activeFetchDetails: any = [];
	use_output: any[] = [];
	hidden: any[] = [];
	edit: boolean = false;
	selected_field: any[] = [];
	event_id: any;
	loading: boolean = false;
	output_type_selected: any[] = [];
	output_id: any;
	action_data: any;
	isNeedCheckOut: boolean;
	@ViewChild('appDataTable') appDataTable: any;
	@ViewChild('tableActionsTpl') tableActionsTpl: any;
	@ViewChild('actionType') actionType: any;
	@ViewChild('actionDescription') actionDescription: any;
	inputFormEditorEnable: boolean = false;
	enableAddInputOutput: boolean = false;
	imageUploaded: boolean = false;
	uploaded_file: any = undefined;
	filetype: any;
	imageSrc: any;
	isDefaultRadioChecked: boolean = true;
	isRadioFieldRequired: boolean = true;
	isNgSelectRequired: boolean = false;
	isDisableUpdateBtn: boolean = false;
	oldActionInputFormValues: object;
	hideUpdatebtn: boolean = false;
	isHiddenFieldChosen: boolean = false;
	isHiddenConditionChosen: boolean = false;
	details: any;
	initial_hidden_boolean: boolean = false;
	isDynamic: boolean = false;
	isFetchDetails: boolean = false;
	hidden_yes_boolean: boolean = false;
	enableBtnInputForm: boolean = false;
	isValidFieldName: boolean = false;
	nameValidError: any;
	nameValidError1: string;
	enableBtnInputForm1: boolean;
	category_logo: any;
	domainName: string;
	exists = [false, false]
	inputList: any;
	active_pagination = 10;
	nameValidErrorOut: string;
	cat: string = '';
	existing_name = [];
	showInputUpdateButton: boolean = false;

	constructor(
		private fb: FormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private actionService: ActionsService,
		private ts: ToasterService,
		public settingsService: SettingsService,
		private location: Location
	) {
		this.action_id = this.route.snapshot.params.action_id;
		this.config.remoteUrl = `actions/${this.action_id}/get-input-list/`
		this.actionService.getActionDetails(this.action_id).subscribe(data => {
			this.action_data = data;
			if (data) {
				this.buildInputForm(data);
			} else {
				this.buildInputForm();
			}
		}, error => {
			this.router.navigate(['/error/not-found']);
		});
		this.getActionDetails();
	}


	ngOnInit() {
		// this.buildInputForm();
		this.loadcolumns();
		this.settingsService.user$.subscribe(settings => {
			// console.log(settings)
			this.domainName = settings['domain_name'];
		});
		$('#addInputModal').on('hide.bs.modal', () => {
			this.nameValidError = ''
			this.nameValidError1 = ''
			this.nameValidErrorOut = ''
			this.getActionDetails();
			$('body').css('overflow', 'auto');
		})
		$('#addInputModal').on('show.bs.modal', () => {
			$('body').css('overflow', 'hidden');
		})
		$('#addOutputModal').on('hide.bs.modal', () => {
			$('body').css('overflow', 'auto');
		})
		$('#addOutputModal').on('show.bs.modal', () => {
			$('body').css('overflow', 'hidden');
		})
	}

	// get details
	getActionDetails() {
		this.fields = [];
		this.actionService.getAllInputs(this.action_id).subscribe((resp: any) => {
			this.details = resp['data'];
			if (resp && resp['data'].length > 0) {
				resp['data'].forEach(element => {
					let isFound = this.fields.findIndex((e) => e.id == element.id)
					if (isFound == -1) {
						this.fields.push({ id: element.id, text: element.name })
					}
				});
			}
		})
	}

	// to navigate to previous page 
	goBack() {
		this.location.back();
		this.inputForm.disable();
	}

	back() {
		$('#delete_input_form').modal('hide');
	}

	loadcolumns() {
		this.columns = [
			{
				name: 'Name',
				prop: 'name',
				sortable: true,
				cellTemplate: '',
				cellClass: 'project-create-pop'
			},
			{
				name: 'Type',
				prop: 'type_in_word',
				sortable: false,
				cellTemplate: this.actionType,
				cellClass: 'project-create-pop'
			},
			{
				name: 'Description',
				prop: 'description',
				sortable: false,
				cellTemplate: this.actionDescription,
				cellClass: 'project-create-pop'
			},
			{
				name: 'Input/Output',
				prop: 'io_type_in_word',
				sortable: false,
				cellTemplate: '',
				cellClass: 'project-create-pop'
			},
			{
				name: '',
				cellTemplate: this.tableActionsTpl,
				headerClass: 'width15',
				sortable: false,
				prop: 'Action'
			}
		]
	}

	// build form
	buildInputForm(data?: any) {
		if (data) {
			this.cat = data.category;
		}
		this.showInputUpdateButton = false
		this.inputForm = this.fb.group({
			'name': [data ? data.action_name : '', [Validators.required, Validators.maxLength(50), Validators.pattern(/^([a-z][a-z0-9-]*)$/)]],
			'description': [data ? data.action_description : '', [Validators.required, Validators.pattern(/[^\s]+/)]],
			'internal_name': [data ? data.internal_name : '', [Validators.required, Validators.pattern('[a-z0-9_]+')]],
			'checkout_source': [data ? data.checkout_source : '', Validators.required],
			'category': [data ? data.category : this.cat, [Validators.required, Validators.maxLength(50)]],
			// 'category_logo': [data ? data['category_logo'] : ''],
			'environment': [data ? data.environment : false, Validators.required],
			'project': [data ? data.project : false, Validators.required],
			'base_image': [data ? data.base_image : '']
		})
		this.enable = false;
		if (data && data.action_name != null) {	
			if (data.internal_name != null && data.category != null) {
				this.enableAddInputOutput = true;
				this.showInputUpdateButton = true
			}
			this.enable = true;
			this.inputForm.disable()
			this.inputFormEditorEnable = false;
			if (data.checkout_source == true) {
				this.isNeedCheckOut = true;
				this.checkout_selected.push(this.options[0]);
			} else {
				this.isNeedCheckOut = false;
				this.checkout_selected.push(this.options[1]);
			}
			
			if (data.environment == true) {
				this.environment_selected.push(this.options[0]);
			} else {
				this.environment_selected.push(this.options[1]);
			}
			if (data.project == true) {
				this.project_selected.push(this.options[0]);
			} else {
				this.project_selected.push(this.options[1]);
			}
			if (data['category_logo'].length != 0) {
				this.category_logo = this.baseUrl + data['category_logo'].slice(1) //GetApiurl(data['category_logo'].substr(1)) + "?" + Math.floor(Math.random() * 100) + 1
			}
			// let index = this.action_types.findIndex(type => type.id == data.type)
			// this.action_type = [this.action_types[index]];
		} else {
			this.inputFormEditorEnable = true;
			this.checkout_selected = this.environment_selected = this.project_selected = [this.options[1]];
		}
	}

	nameAlreadyExist(name, type) {
		if (type == 'internalName' && name.length != 0) {
			type = '_interName_'
			this.exists[0] = false
		}
		else {
			name = this.inputForm.controls['category'].value
			type = this.inputForm.controls['name'].value
			this.exists[1] = false
		}

		this.actionService.isActionNameExist(name, type, this.domainName).subscribe((resp: any) => {

			if (resp['type'] == '_interName_' && resp['exist']) {
				this.exists[0] = true
			}
			if (resp['type'] != '_interName_' && resp['exist']) {
				this.exists[1] = true
			}
		})
	}

	categoryLogoFatch(event) {
		this.cat = event
		// console.log("cat",event,this.baseUrl)
		this.category_logo = this.baseUrl + "media/admins/" + this.domainName + "/Action-Logo/" + event + ".png"
	}
	// on dropdown values selected
	onSelected(event, type) {
		switch (type) {
			case 'checkout':
				if (event.id == 1) {
					this.inputForm.controls['checkout_source'].setValue(true)
					this.isNeedCheckOut = this.inputForm.controls['checkout_source'].value;
					this.isNeedCheckOut = true;
					this.environment_selected = [this.options[0]];
					this.inputForm.controls['environment'].setValue(true)
					this.project_selected = [this.options[0]]
					this.inputForm.controls['project'].setValue(true)
				} else {
					this.inputForm.controls['checkout_source'].setValue(false)
					this.isNeedCheckOut = this.inputForm.controls['checkout_source'].value;
					this.isNeedCheckOut = false;
					this.environment_selected = [this.options[1]];
					this.inputForm.controls['environment'].setValue(false)
					this.project_selected = [this.options[1]]
					this.inputForm.controls['project'].setValue(false)
				}
				break;
			case 'environment':
				if (event.id == 1) {
					this.inputForm.controls['environment'].setValue(true)
					this.checkout_selected = [this.options[0]];
					this.inputForm.controls['checkout_source'].setValue(true)
					this.inputForm.controls['project'].setValue(true)
					this.environment_selected = [this.options[0]];
					this.project_selected = [this.options[0]]
				} else {
					this.inputForm.controls['environment'].setValue(false)
					this.isNeedCheckOut = false;
					this.checkout_selected = [this.options[1]];
					this.inputForm.controls['checkout_source'].setValue(false)
					this.inputForm.controls['project'].setValue(false)
					this.environment_selected = [this.options[1]];
					this.project_selected = [this.options[1]]

				}
				break;
			case 'project':
				if (event.id == 1) {
					this.inputForm.controls['project'].setValue(true)
					this.checkout_selected = [this.options[0]];
					this.inputForm.controls['checkout_source'].setValue(true)
					this.inputForm.controls['environment'].setValue(true)
					this.environment_selected = [this.options[0]];
					this.project_selected = [this.options[0]]
				} else {
					this.inputForm.controls['project'].setValue(false)
					this.isNeedCheckOut = false;
					this.checkout_selected = [this.options[1]];
					this.inputForm.controls['checkout_source'].setValue(false)
					this.inputForm.controls['environment'].setValue(false)
					this.environment_selected = [this.options[1]];
					this.project_selected = [this.options[1]]
				}
				break;
		}
	}

	hasFormChanged() {
		// console.log("hasin")
		this.isValidFieldName = true;
		this.inputVaild();
		this.actionInputForm.valueChanges.subscribe((latestValues) => {
			this.isDisableUpdateBtn = isObjectsEqual(this.oldActionInputFormValues, latestValues)
			// console.log("value",this.oldActionInputFormValues, latestValues,this.isDisableUpdateBtn)
		})
	}
	// toggleUpdateBtn() {
	// 	if(this.actionInputForm.invalid){
	// 		this.hideUpdatebtn = true;
	// 	}
	// 	if(!this.isDisableUpdateBtn) {
	// 		this.hideUpdatebtn = true;
	// 	}
	// 	else{
	// 	this.hideUpdatebtn = false;
	// 	}
	// }

	cleanUpActionInputBooleans() {
		this.isHiddenFieldChosen = false;
		this.isHiddenConditionChosen = false;
		this.isNgSelectRequired = false;
		this.isValidFieldName = false;
		this.actionInputForm.reset();
		this.enableBtnInputForm = false;
	}

	onRowAction(row, action) {
		this.event_id = row.id;
		this.edit = true;
		this.isDisableUpdateBtn = true;
		this.hideUpdatebtn = true;
		this.loading = false;
		// console.log("de",this.details[0])
		if (action == 'edit') {
			this.getActionList();
			if (row.io_type == 1) {
				if (this.details[0].id == row.id) {
					this.initial_hidden_boolean = true;
				}
				else {
					this.initial_hidden_boolean = false;
				}
				this.input_data = row;
				this.buildInputsForm(row);
				this.oldActionInputFormValues = this.actionInputForm.value;

				// to patch active field
				let type_idx = this.type_options.findIndex(i => i.id == row.type)
				if (type_idx > -1) {
					this.selected_type = [this.type_options[type_idx]];
				}

				// to patch attributes
				let value_type_idx = this.action_attribute_options.findIndex(a => a.text == row.value_type_in_word);
				if (value_type_idx != -1) {
					this.selected_attribute = [this.action_attribute_options[value_type_idx]]
				}

				// to patch fields to match  
				let field_idx = this.fields.findIndex(f => f.text == row.name)
				if (field_idx != -1) {
					this.fields.splice(field_idx, 1)
					let i = this.fields.findIndex(f => f.text == row.hidden_condition)
					if (i != -1) {
						this.selected_field = [this.fields[i]]
					}

				}

				// to patch hidden condition
				let hidden_value_index = this.hidden_value_options.findIndex(h => h.text == row.hidden_value);
				if (row.hidden_value != null) {
					this.activeCondition = [this.hidden_value_options[1]];
					this.hidden_value_boolean = true;
				}
				else {
					this.activeCondition = [this.hidden_value_options[0]];
					this.hidden_value_boolean = false;
				}

				for (const k in row) {
					if (typeof row[k] === 'boolean' && this.actionInputForm.controls[k]) {
						this.actionInputForm.controls[k].setValue(row[k])
						switch (k) {
							case 'multiple':
								if (row[k] == true) {
									this.multiple = [this.io_options[0]];
								} else {
									this.multiple = [this.io_options[1]];
								}
								break;
							case 'required':
								if (row[k] == true) {
									this.required = [this.io_options[0]];
								} else {
									this.required = [this.io_options[1]];
								}
								break;
							case 'dynamic':
								if (row[k] == true) {
									this.dynamic_input = [this.io_options[0]];
								} else {
									this.dynamic_input = [this.io_options[1]];
								}
								break;
							case 'use_output':
								if (row[k] == true) {
									this.use_output = [this.io_options[0]];
								} else {
									this.use_output = [this.io_options[1]];
								}
								break;
							case 'hidden':
								if (row[k] == true) {
									this.hidden = [this.io_options[0]];
								} else {
									this.hidden = [this.io_options[1]];
								}
								break;
							default:
								console.log('default')
								break;
						}
					}
				}
				$('#addInputModal').modal('show');
				this.inputVaild();
			} else {
				this.buildOutputForm(row);
				let output_type_idx = this.action_attribute_options.findIndex(val => row.value_type_in_word == val.text)
				if (output_type_idx > -1) {
					this.output_type_selected = [this.action_attribute_options[output_type_idx]];
				}
				$('#addOutputModal').modal('show');
			}
		} else {
			$('#delete_input_form').modal('show');
		}
	}

	// Save action basic info the upper part or execpt inputs[] and ouptuts[] array details
	onSave() {
		let value = this.inputForm.value;
		this.actionService.create_action_details(this.action_id, value, this.uploaded_file).then((data: any) => {
			this.enable = true;
			this.enableAddInputOutput = true;
			this.ts.pop('success', this.showInputUpdateButton ? 'Action Details Updated':'Action Details Added')
			this.inputFormEditorEnable = false;
			this.showInputUpdateButton = true;
			this.inputForm.disable();
			this.actionService.getActionDetails(this.action_id).subscribe(data => {
				this.action_data = data;
			});
		}, error => { this.ts.pop('error', error.detail) })
	}

	focusIn() {
		let rlInput: HTMLElement = document.querySelector('input[formcontrolname="tagInputField"]');
		rlInput.focus();
	}

	getActionList() {
		this.inputList = []
		this.existing_name = [];
		this.actionService.getInputList(this.action_id).subscribe((resp: any) => {
			if (resp && resp['records'].length > 0) {
				resp['records'].forEach(element => {
					this.inputList.push({ type: element.io_type, name: element.name, value_type: element.value_type_in_word })
					this.existing_name.push(element.name)
				});
			}
		})
	}

	open_modal(type) {
		this.loading = false;
		this.imageSrc = '';
		this.getActionList();
		this.getActionDetails();
		if (type == 'input') {
			this.edit = false;
			this.multiple = []
			this.required = [];
			this.dynamic_input = [];
			this.use_output = [];
			this.hidden = [];
			this.selected_attribute = [];
			this.activeCondition = [{ id: -1, text: 'Allow any' }];
			if (this.details.length == 0) {
				this.initial_hidden_boolean = true;
			}
			else {
				this.initial_hidden_boolean = false;
			}
			this.buildInputsForm()
			$('#addInputModal').modal('show');
			// this.inputVaild();
		}
		else if (type == 'output') {
			this.edit = false;
			this.output_type_selected = [];
			this.buildOutputForm();
			$('#addOutputModal').modal('show');
		}
	}

	//Inputs Form
	buildInputsForm(data?: any) {
		this.actionInputForm = this.fb.group({
			name: [data ? data.name : '', [Validators.required, Validators.pattern('[A-Za-z0-9]+')]],
			type: [data ? data.type_in_word : 'text', Validators.required],
			multiple: [data ? data.multiple : false, Validators.required],
			value_type: [data ? data.value_type_in_word : '', Validators.required],
			label: [data ? data.label : '', [Validators.required, Validators.maxLength(50)]],
			required: [data ? data.required : '', Validators.required],
			description: [data ? data.description : 'some description', Validators.required],
			value: [data ? data.value : ''],
			// value:this.canValidateOnlyIfRadioField.length>0 ? [data ? data.value : '',Validators.required]:[data ? data.value : ''],
			default_value: [data ? data.default_value : ''],
			dynamic: [data ? data.dynamic : '', Validators.required],
			dynamic_url: [data ? data.dynamic_url : ''],
			isFetchDetails: [data ? data.isFetchDetails : ''],
			use_output: [data ? data.use_output : true, Validators.required],
			hidden: [data ? data.hidden : '', Validators.required],
			hidden_condition: [data ? data.hidden_condition : ''],
			hidden_value: [data ? data.hidden_value : 'Allow any'],
			allow_any: [data ? data.allow_any : true]
		});
		if (data == undefined) {
			this.loadDefaultFormValues();
		}
		if (data ? data.dynamic : false) {
			this.isDynamic = data.dynamic
		}

	}

	private loadDefaultFormValues() {
		this.selected_type = [{ id: 1, text: 'text' }];
		this.required = [this.io_options[0]]; //0 true 1 false to toggle input form save button.
		this.actionInputForm.controls['required'].setValue(true);
		this.dynamic_input = [this.io_options[1]];
		this.activeFetchDetails = [this.io_options[1]]
		this.actionInputForm.controls['dynamic'].setValue(false);
		this.use_output = [this.io_options[1]];
		this.actionInputForm.controls['use_output'].setValue(false);
		this.hidden = [this.io_options[1]];
		this.actionInputForm.controls['hidden'].setValue(false);
		this.selected_attribute = [this.action_attribute_options[0]];
		this.actionInputForm.controls['value_type'].setValue(this.action_attribute_options[0].text);
	}

	buildOutputForm(value?: any) {
		this.action_attribute_options = this.action_attribute_options;
		this.outputForm = this.fb.group({
			name: [value ? value.name : '', Validators.required],
			value_type: [value ? value.value_type_in_word : '', Validators.required],
			value: [value ? value.value : '']
		})
	}

	toggleNgSelectRequired(value) {
		this.hasFormChanged()
		if (value.length < 2) {
			this.isNgSelectRequired = true;
		}
		else {
			this.isNgSelectRequired = false;
		}
	}

	toggleRadioFieldRequired(value) {
		this.hasFormChanged()
		if (value.length < 2) {
			this.isRadioFieldRequired = true;
		}
		else {
			this.isRadioFieldRequired = false;
		}
	}

	toggleRadio(value) {
		return this.isDefaultRadioChecked = value == 'default'
	}
	//Input type selected
	onTypeSelected(event) {
		this.hasFormChanged()
		this.actionInputForm.controls['type'].setValue(event.text);
		let val = this.actionInputForm.controls['type'].value;
		this.dynamic_input = [this.io_options[1]];
		this.actionInputForm.controls['dynamic'].setValue(false);
		this.actionInputForm.controls['value'].setValue([]);
		this.isDynamic = false;
		if (val != 'text') {
			this.use_output = [this.io_options[1]];
			this.actionInputForm.controls['use_output'].setValue(false);
			// this.actionInputForm.controls['value'].setValue([]);
		}
		if (val == 'text' || val == 'number' || val == 'textarea') {
			this.isNgSelectRequired = false;
			this.actionInputForm.controls['value'].setValue('');
			this.actionInputForm.controls['default_value'].setValue('');
		} else if (val == 'radio') {
			this.isNgSelectRequired = false;
			this.actionInputForm.controls['value'].setValue(["yes", "no"])
			this.actionInputForm.controls['default_value'].setValue('');
		} else {
			this.isNgSelectRequired = true;
			this.actionInputForm.controls['value'].setValue([]);
			this.isDisableUpdateBtn = true;
			this.actionInputForm.controls['default_value'].setValue([])
		}
		if (val != 'ng-select') {
			this.isNgSelectRequired = false;
			this.actionInputForm.controls['multiple'].setValue(false);
		}
		this.inputVaild();

	}

	inputVaild() {
		this.nameValidError = ''
		const regexp = /^[a-zA-Z0-9_]*$/;
		this.isDisableUpdateBtn = true;
		const regexp1 = /^[a-zA-Z_]*$/;
		const pattern = /^[a-z0-9-]*[a-z]$/g
		this.enableBtnInputForm = true;
		this.enableBtnInputForm1 = true;
		if (this.actionInputForm) {

			let op = this.actionInputForm.controls['type'].value;

			let actionAttibute = this.actionInputForm.controls['value_type'].value;

			if (!regexp.test(this.actionInputForm.controls['name'].value) && actionAttibute == 'Attribute') {
				this.nameValidError = 'Use only letters numbers and underscore'
				this.enableBtnInputForm = false;
			}
			if (this.actionInputForm.controls['name'].value.length == 0) {
				this.nameValidError = 'Please enter Name'
				this.enableBtnInputForm = false;
			}
			else if (this.inputList.some(input => input.name === this.actionInputForm.controls['name'].value && input.type === 1)) {
				if (this.oldActionInputFormValues != undefined) {
					if (this.oldActionInputFormValues['name'] != this.actionInputForm.controls['name'].value) {
						this.nameValidError = 'Name Already exist'
						this.enableBtnInputForm = false;
					}
				}
				else {
					this.nameValidError = 'Name Already exist'
					this.enableBtnInputForm = false;
				}

			}

			if (!regexp1.test(this.actionInputForm.controls['name'].value[0]) && actionAttibute == 'Attribute') {
				this.nameValidError = 'Should Starts With alphabets or underscore'
				this.enableBtnInputForm = false;
			}

			if (this.actionInputForm.controls['name'].value.length == 0 || this.actionInputForm.controls['label'].value.length == 0 || this.actionInputForm.controls['label'].value.length > 50) {
				this.enableBtnInputForm = false;
				this.isDisableUpdateBtn = true;
			}



			if (op == 'radio') {
				if (this.actionInputForm.controls['value'].value.length <= 1 && !this.actionInputForm.controls['dynamic'].value) {
					this.enableBtnInputForm = false;
				}
				if (this.actionInputForm.controls['dynamic'].value) {
					if (this.actionInputForm.controls['dynamic_url'].value.length == 0) {
						this.isDisableUpdateBtn = true;
						this.enableBtnInputForm = false;
					}
				}
			}

			if (op == 'ng-select') {
				if (this.actionInputForm.controls['value'].value.length <= 0 && !this.actionInputForm.controls['dynamic'].value) {
					this.enableBtnInputForm = false;
				}
				if (this.actionInputForm.controls['dynamic'].value) {
					if (this.actionInputForm.controls['dynamic_url'].value.length == 0) {
						this.isDisableUpdateBtn = true;
						this.enableBtnInputForm = false;
					}
				}
			}

			this.nameValidError1 = ''
			if (actionAttibute == 'File') {
				const fieldNameValue = this.actionInputForm.controls['name'].value
				// console.log('yes',fieldNameValue);
				this.fieldNameShouldContain(fieldNameValue);
				if (pattern.test(fieldNameValue)) {
					this.enableBtnInputForm = true;
					this.isValidFieldName = true;
					this.nameValidError = ''

				} else {
					this.enableBtnInputForm = false;
					// this.isValidFieldName = true;
				}
			}
		}
		if (this.outputForm) {
			this.nameValidErrorOut = ''

			if (this.outputForm.controls['name'].value.length == 0) {
				this.nameValidErrorOut = 'Please enter Name'
				this.enableBtnInputForm = false;
			}
			else if (this.inputList.some(input => input.name === this.outputForm.controls['name'].value && input.type === 2 && input.value_type === this.outputForm.controls['value_type'].value)) {
				this.nameValidErrorOut = 'Name Already exist'
				this.enableBtnInputForm = false;
			}

		}


	}

	// on option selected
	onOptionSelected(event, form_name) {
		this.hasFormChanged()
		// console.log("kk",event,form_name);
		if (event.id == 1) {
			// this.hidden_yes_boolean = true;
			this.isNgSelectRequired = true;
			// this.actionInputForm.controls['dynamic_url'].setValue(['']);
			// this.actionInputForm.controls['value'].setValue([]);
			if (form_name == 'hidden') {
				this.isHiddenFieldChosen = true;
				// this.actionInputForm.value['hidden_value'].setValue("Allow any");hidden
				// this.actionInputForm.controls["hidden"].setValue('true');
			}
			// this.isHiddenFieldChosen = true;
			this.actionInputForm.controls[form_name].setValue(true);
			if (form_name == 'dynamic') {
				// this.actionInputForm.controls['dynamic_url'].setValue('');
				this.isDynamic = true;
				this.actionInputForm.controls['value'].setValue([]);
				this.isDisableUpdateBtn = true;

				this.isNgSelectRequired = true;
				this.actionInputForm.get('dynamic').clearValidators()
				this.actionInputForm.get('dynamic').updateValueAndValidity()
				this.actionInputForm.controls['dynamic'].clearAsyncValidators()
				// console.log("kk");

			}
			if (form_name == 'isFetchDetails') {
				this.isFetchDetails = true;
			}
		} else {
			this.hidden_yes_boolean = false;
			// this.actionInputForm.controls['dynamic_url'].setValue(['']);
			// this.actionInputForm.controls['value'].setValue([]);
			this.actionInputForm.controls[form_name].setValue(false);
			if (form_name == 'hidden') {
				this.isHiddenFieldChosen = false;
				// 	// this.actionInputForm.controls['hidden_value'].setValue("");
			}
			// console.log("kk2");
			if (form_name == 'dynamic') {
				this.actionInputForm.controls['dynamic_url'].setValue('');
				this.isDynamic = false;
				this.actionInputForm.controls['value'].setValue([]);
				this.isNgSelectRequired = true;

			}
		}
	}

	fieldNameShouldContain(value: string) {
		const lowerCasePattern = /^[a-z]+$/
		const regexp = /^[a-zA-Z0-9\d-]*$/;

		if (!regexp.test(value)) {
			this.nameValidError = 'use only letters numbers and hyphens'
			this.enableBtnInputForm = false;
		}

		if (value.includes("_")) {
			this.enableBtnInputForm = false;
			this.nameValidError = 'Should contain hyphens, not an underscore';
		}

		if (!lowerCasePattern.test(value[0])) {
			this.nameValidError1 = 'Should Starts With lowercase letter';
			this.enableBtnInputForm1 = false;
		}

		if (!lowerCasePattern.test(value.slice(-1))) {
			this.enableBtnInputForm = false;
			this.nameValidError = 'Should Ends With lowercase letter';
		}

		if (value.length == 0) {
			this.enableBtnInputForm = false;
			this.nameValidError = 'please enter field name';
		}
		if (this.nameValidError1 != '' || this.nameValidError != '') {
			this.isDisableUpdateBtn = true;
		}
	}
	// on Action Attribute Selected
	onAttributeSelected(event) {
		this.hasFormChanged()
		this.actionInputForm.controls['value_type'].setValue(event.text);
		this.inputVaild()
		if (this.actionInputForm.controls['type'].value == 'ng-select') {
			if (event.text == 'AttributeArray') {
				this.multiple = [this.io_options[0]];
				this.actionInputForm.controls['multiple'].setValue(true);
			} else {
				this.multiple = [this.io_options[1]];
				this.actionInputForm.controls['multiple'].setValue(false);
			}
		}
	}

	// Filed to match selected
	onFieldSelected(event) {
		this.hasFormChanged()
		this.hidden_yes_boolean = false;
		this.isHiddenFieldChosen = false;
		this.actionInputForm.controls['hidden_condition'].setValue(event.text);
		this.activeCondition = [{ id: -1, text: 'Allow any' }];
		this.actionInputForm.controls['hidden_value'].setValue('Allow any');
		this.actionInputForm.controls['allow_any'].setValue(true);
	}

	// hidden condition selected
	onConditionSelected(event) {
		this.hasFormChanged();
		if (event.id == -1) {
			this.hidden_value_boolean = false;
			this.isHiddenConditionChosen = true;
			this.isHiddenFieldChosen = false;
			this.actionInputForm.controls['hidden_value'].setValue('Allow any');
			this.actionInputForm.controls['allow_any'].setValue(true);
		} else {
			this.actionInputForm.controls['hidden_value'].setValue('');
			this.hidden_value_boolean = true;
			this.actionInputForm.controls['allow_any'].setValue(false);
		}
		// this.hidden_yes_boolean = false;
	}

	//To  save input 
	saveInput(isUpdate: boolean = false) {
		this.nameValidError1 = ''
		this.nameValidError = ''
		this.loading = true;
		this.hasFormChanged()
		let form_value = this.actionInputForm.value;
		// console.log("gg",this.actionInputForm.value)
		if (this.edit) {
			form_value['id'] = this.event_id;
		}
		this.actionService.create_action_input(this.action_id, form_value).subscribe((response: any) => {
			this.loading = false;
			if (isUpdate)
				this.ts.pop('success', 'Input Field Updated');
			else
				this.ts.pop('success', 'Input Field Saved');
			$('#addInputModal').modal('hide');
			this.appDataTable.refreshData();
			this.actionInputForm.reset();
		}, error => {
			this.loading = false;
			this.ts.pop('error', error.detail)
		})
		this.getActionDetails();
		this.enableBtnInputForm = false;
	}


	ChangingPaginationValue($event) {
		this.config.pageSize = Number($event.target.value);
		this.appDataTable.reConfigAndRefresh(this.config);

	}


	//output functions

	//output type selected
	onOutputTypeSelected(event) {
		this.outputForm.controls['value_type'].setValue(event.text);
	}

	// save output 
	onOutputSave() {
		this.loading = true;
		let output_form_value = this.outputForm.value;
		if (this.edit == true) {
			output_form_value['id'] = this.event_id;
		}
		this.actionService.create_action_output(this.action_id, output_form_value).subscribe(data => {
			this.loading = false;
			this.ts.pop('success', 'Output Saved');
			$('#addOutputModal').modal('hide');
			this.edit = false;
			this.appDataTable.refreshData();
			this.outputForm.reset();
		}, error => {
			this.loading = false;
			this.ts.pop('error', error.detail)
		})
	}

	// delete input or output 
	deleteInputorOutput() {
		this.loading = true;
		this.actionService.deleteInputorOutput(this.event_id).subscribe(data => {
			this.loading = false;
			this.ts.pop('success', 'Input Field Deleted');
			$('#delete_input_form').modal('hide');
			this.appDataTable.refreshData();
		}, error => {
			this.loading = false;
			this.ts.pop('error', error.detail);
			$('#delete_input_form').modal('hide');
		})
	}

	editInputFormEditor() {
		this.inputFormEditorEnable = true;
		this.inputForm.enable();
		this.enable = false;
	}

	@Input('enabled')
	set enabled(data) {
		if (data == false) {
			this.inputFormEditorEnable = false;
		}
	}

	onImageSelected(event) {
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0];
			this.uploaded_file = file;
			this.filetype = file.type;
			const reader = new FileReader();
			reader.onload = e => this.imageSrc = reader.result;
			reader.readAsDataURL(file);
			this.imageUploaded = true;
		}
	}

	onImageSelected1(event) {
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0];
			this.uploaded_file = file;
			this.filetype = file.type;
			const reader = new FileReader();
			reader.onload = e => this.category_logo = reader.result;
			reader.readAsDataURL(file);
			this.imageUploaded = true;
		}
	}

	nullSearchData(str) {
        str.target.value = "";
    }

	ngOnDestroy() {

		// $('body').css('overflow','hidden');

	}


}
