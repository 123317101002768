import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { InterfaceService } from '@app/interfaces';

@Injectable({
    providedIn: 'root'
})
export class ApplicationService extends InterfaceService {

    constructor(private http: HttpClient) {
        super();
    }

    createApplication(application) {
        return this.http.post(this.getApiUrl('application/'), JSON.stringify(application), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    deleteApplication(id) {
        const url = this.getApiUrl(`application/${id}/deleteapp/`);
        return this.http.get(url, this.getHttpOptions())
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    getApplications(branch_id) {
        return this.http.get(this.getApiUrl(`application/?offset=0&limit=3&branch_id=${branch_id}/`), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    getApplication(id, id_type = 'application') {
        return this.http.get(this.getApiUrl(`application/${id}/get_application/?type=${id_type}/`), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    getResourceQuotaStatus(applicationId: number) {
        return this.http.get(this.getApiUrl(`application/${applicationId}/get-resource-quota-status/`), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    getElasticApm(id) {
        return this.http.get(this.getApiUrl(`elasticapm/${id}/get-elastic-apm/`), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    updateApplication(app_id, details) {
        return this.http.post(this.getApiUrl(`application/${app_id}/update/`), JSON.stringify(details), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    get_application_details(application_id) {
        return this.http.get(this.getApiUrl(`application/${application_id}/get_application_details/`), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    get_branch_stage(application_id) {
        return this.http.get(this.getApiUrl(`application/${application_id}/get-application-stage/`), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    addDependency(details) {
        return this.http.post(this.getApiUrl(`dependency/`), JSON.stringify(details), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    listDependency() {
        return this.http.get(this.getApiUrl(`dependency/dependency-list/`), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    deleteDependency(id) {
        return this.http.delete(this.getApiUrl(`dependency/${id}/`), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    dependencyNameExists(name) {
        return this.http.post(this.getApiUrl(`dependency/verify-name/`), JSON.stringify(name), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    addDependencyValue(details) {
        return this.http.post(this.getApiUrl(`dependency_value/`), JSON.stringify(details), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    updateDependencyValue(id, details) {
        return this.http.put(this.getApiUrl(`dependency_value/${id}/`), JSON.stringify(details), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    deleteDependencyValue(id) {
        return this.http.get(this.getApiUrl(`dependency_value/${id}/delete/`), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    getMetrcsList(applicationId: number) {
        return this.http.get(this.getApiUrl(`application_metrics/?applicationId=${applicationId}/`), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    saveMetricsList(applicationId: number, metricList: Object) {
        // tslint:disable-next-line:max-line-length
        return this.http.put(this.getApiUrl(`application_metrics/${applicationId}/save_details/`), JSON.stringify(metricList), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    get_metrics(application_id) {
        return this.http.get(this.getApiUrl(`application_metrics/${application_id}/get_metrics/`), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    // Getting next version
    getNextVersion(details: any) {
        return this.http.post(this.getApiUrl(`versioning/get-next-version/`), JSON.stringify(details), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    // Registry details update
    updateRegistryDetails(details, id) {
        return this.http.post(this.getApiUrl(`application/${id}/registry_details/`), JSON.stringify(details), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    // Check registry details
    check_registry_details(application_id) {
        return this.http.get(this.getApiUrl(`application/${application_id}/check-registry/`), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    getStageList(id) {
        return this.http.get(this.getApiUrl(`application/${id}/get-stage-list/`), this.getHttpOptions('json')).pipe(
            tap(res => res),
            catchError(this.handleError)
        );
    }

    getApplicationInfo() {
        return this.http.get(this.getApiUrl(`application/application-info/`), this.getHttpOptions('json')).pipe(
            tap(res => res),
            catchError(this.handleError)
        );
    }

    // Server details update
    updateServerDetails(details, id) {
        return this.http.post(this.getApiUrl(`application/${id}/server_details/`), JSON.stringify(details), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    // elastic apm update
    updateElasticApm(details, id) {
        return this.http.post(this.getApiUrl(`elasticapm/${id}/elastic-apm/`), JSON.stringify(details), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    checkApplicationServerType(data) {
        return this.http.post(this.getApiUrl('application/check-server-type/'), JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    // Retrieve Server Details
    getServerConfig(id) {
        return this.http.get(this.getApiUrl(`application/${id}/retrieve_server/`), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    // Check Configuration Setup Completed
    checkDeployConfig(id) {
        return this.http.get(this.getApiUrl(`application/${id}/check_deploy_config/`), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    // Check Monitoring Configuration Completed
    checkMonitoringConfig(id) {
        return this.http.get(this.getApiUrl(`application/${id}/check_monitor_config/`), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    // Check Jboss details true or false
    jbossTesting(data) {
        return this.http.post(this.getApiUrl(`application/jboss-testing/`), JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    // Delete Jboss Deployment Track
    deleteJbossDeployment(data) {
        return this.http.post(this.getApiUrl(`jboss-deploy/delete-deployment/`), JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    re_deploy(id) {
        const url = this.getApiUrl(`jboss-deploy/${id}/re-deploy/`);
        return this.http.get(url, this.getHttpOptions('json'))
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    // Jira
    jiraCredentials(id, data) {
        const url = this.getApiUrl(`application/${id}/jira-tickets-credentials/`);
        return this.http.post(url, JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    jiraDetails(id) {
        const url = this.getApiUrl(`application/${id}/jira-tickets-details/`);
        return this.http.get(url, this.getHttpOptions('json'))
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    // FreshDesk
    freshDeskCredentials(id, data) {
        const url = this.getApiUrl(`application/${id}/fresh-desk-tickets-credentials/`);
        return this.http.post(url, JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    freshDeskDetails(id) {
        const url = this.getApiUrl(`application/${id}/fresh-desk-tickets-details/`);
        return this.http.get(url, this.getHttpOptions('json'))
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    applicationListUser(id) {
        const url = this.getApiUrl(`application/${id}/user-application/`);
        return this.http.get(url, this.getHttpOptions('json'))
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    addMembersToApp(app_id, data) {
        const url = this.getApiUrl(`application/${app_id}/add-members/`);
        return this.http.post(url, JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    removeMemnerFromApp(app_id, user_id) {
        const url = this.getApiUrl(`application/${app_id}/delete-members/${user_id}/`);
        return this.http.get(url, this.getHttpOptions('json'))
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    userExist() {
        const url = this.getApiUrl(`user/check-exist/`);
        return this.http.get(url, this.getHttpOptions('json'))
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    listApplication(data) {
        const url = this.getApiUrl(`application/`, data);
        return this.http.get(url, this.getHttpOptions('json')).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    listAllApplication() {
        const url = this.getApiUrl(`application/all-application/`);
        return this.http.get(url, this.getHttpOptions('json')).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    createApplicationEnvironment(data) {
        return this.http.post(this.getApiUrl('environment/'), JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    getEnvironmentList(pk) {
        const url = this.getApiUrl(`environment/${pk}/get-environment-list/`);
        return this.http.get(url, this.getHttpOptions('json')).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    getDeploymentTargets(pk) {
        const url = this.getApiUrl(`environment/${pk}/get-deployment-targets/`);
        return this.http.get(url, this.getHttpOptions('json')).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    get_application_name(id) {
        const url = this.getApiUrl(`application/${id}/get-application-name/`);
        return this.http.get(url, this.getHttpOptions('json')).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    get_application_metrics(application_id) {
        return this.http.get(this.getApiUrl(`application_metrics/${application_id}/get-application-metrics/`), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    listSystemDashboardMetrics(application_id) {
        return this.http.get(this.getApiUrl(`application_metrics/${application_id}/get-system-dashboard-metrics/`), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }
    
    get_pipeline_details(id) {
        return this.http.get(this.getApiUrl(`application_metrics/${id}/get_pipeline_details/`), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    listCustomerDashboardActions(applicationId:any) {
        return this.http.get(this.getApiUrl(`application_metrics/${applicationId}/get-customer-dashboard-actions`), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    listCustomerDashboardMetrics (applicationId:any) {
        return this.http.get(this.getApiUrl(`application_metrics/${applicationId}/get-customer-dashboard-metrics`), this.getHttpOptions('json'))
        .pipe(
            tap(response => response),
            catchError(this.handleError)
        );
    }
    
    // Registry name check
    check_registry_name_exists(name, id) {
        const url = `application/${id}/check-registry-name-exists/`;
        return this.http.post(this.getApiUrl(url), JSON.stringify(name), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    getStepList(id) {
        return this.http.get(this.getApiUrl(`application/${id}/get_steps/`, { 'application_id': id }), this.getHttpOptions('json')).pipe(
            tap(res => res),
            catchError(this.handleError)
        );
    }

    createTarget(id, data) {
        return this.http.post(this.getApiUrl(`environment/${id}/create-target/`), JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    add_or_update_group(id, data) {
        const url = this.getApiUrl(`application/${id}/group-create-or-update/`);
        return this.http.post(url, JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    get_group_list(id) {
        const url = this.getApiUrl(`application/${id}/get-group-list/`);
        return this.http.get(url, this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    get_users_for_group(app_id) {
        const url = `application/${app_id}/user-for-group/`;
        return this.http.get(this.getApiUrl(url), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    get_group_details(group_id) {
        const url = `application/${group_id}/get-group-details/`;
        return this.http.get(this.getApiUrl(url), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    delete_group_details(group_id) {
        const url = `application/${group_id}/delete-group-details/`;
        return this.http.get(this.getApiUrl(url), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    check_group_name(id, name) {
        const data = { 'group_name': name };
        const url = this.getApiUrl(`application/${id}/check-group-name/`);
        return this.http.post(url, JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    getmarketplace(id) {
        return this.http.get(this.getApiUrl(`marketplace/${id}/get_marketplace/`), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    searchmarketplace(id, data) {
        return this.http.get(this.getApiUrl(`marketplace/${id}/get_marketplace/`, { 'searchText': data }), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    deletecategory(id) {
        return this.http.get(this.getApiUrl(`marketplace/${id}/delete-category/`), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }
    categorynameCheck(data) {
        return this.http.post(this.getApiUrl(`marketplace/check-category-name/`), JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }
    existngactionadd(id, data) {
        return this.http.post(this.getApiUrl(`marketplace/${id}/action-add/`), JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    get_pipeline_completion_duration(app_id, pipeline?: any) {
        // return this.http.post()
        return this.http.post(this.getApiUrl(`application_metrics/get-pipeline-completion-duration/?app_id=${app_id}/`), pipeline, this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    getTourData(data) {
        return this.http.post(this.getApiUrl('application/get-tour-data/'), JSON.stringify(data), this.getHttpOptions()).pipe(
            tap(Res => Res),
            catchError(this.handleError)
        );
    }
    getProjectLength(data, pk) {
        return this.http.post(this.getApiUrl(`application/${pk}/get-project-length/`), JSON.stringify(data), this.getHttpOptions()).pipe(
            tap(Res => Res),
            catchError(this.handleError)
        );
    }

    addOrRemoveAppBookmark(data = {}) {
        return this.http.post(this.getApiUrl(`application-bookmark`), JSON.stringify(data), this.getHttpOptions()).pipe(
            tap(Res => Res),
            catchError(this.handleError)
        );
    }
}
