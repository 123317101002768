// This file can be replaced during build by using the  array.
//  replaces  with .
// The list of file replacements can be found in .

export const environment = {
  production: false,
    baseUrl : "https://vitsangli.digilabs.ai/backend/",
    dynamicActionBaseUrl:"https://vitsangliaction.digilabs.ai/",
    podLoggerBaseUrl:'ws:///206.189.140.208:32673/'
};
  
export const RazorpayId = {
  razorpay_id: 'rzp_test_dn7jVSKeY9fyPM'
}

/*
 * In development mode, to ignore zone related error stack frames such as
 * ,  for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

