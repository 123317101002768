// Library elements
import { Component, OnInit, ViewChild, EventEmitter, Output, Input, HostListener } from '@angular/core';
import { Router, Params, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
import { NotificationService, ApplicationService, ProjectBranchService, UserService } from '@app/services';

const screenfull = require('screenfull');
const browser = require('jquery.browser');
declare var $: any;
declare var jQuery: any;

import { InterfaceComponent } from '@app/interfaces';
import { UserblockService } from '../sidebar/userblock/userblock.service';
import { SettingsService, MenuService, pubnubKey, GetApiurl, TOASTERCONFIG } from '@app/core';
import { AuthService } from '@app/auth';
import { AppPreferences } from '@app/models';
import { PubNubAngular } from 'pubnub-angular2';
import { ToasterService, Toast } from 'angular2-toaster';
import { UserType } from '@app/models/user';
import { ValueTransformer } from '@angular/compiler/src/util';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NavigationControlService } from '@app/shared/directives/custom-devops-header/navigation-control.service';
import { environment } from 'src/environments/environment';
import { timer } from 'rxjs';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends InterfaceComponent implements OnInit {

    helpBool: boolean = false;
    baseUrl = environment.baseUrl
    navCollapsed = true; // for horizontal layout
    menuItems = []; // for horizontal layout
    enableAppList = false; // application list enable or disable
    enableBranchList = false; // branch list from project select enable or disable
    branch_list = [];
    app_list = [];
    current_project;
    current_app = {};
    app_id;
    project_id;
    branch_id;
    notification_obj = [];
    unread_notification = 0;
    show_notify = false;
    user: any;
    user_type: any;
    user_details: any;
    company_logo = 'assets/img/logo/logo_white.png';
    profile_image = 'assets/img/default-user.png';
    toasterConfig = TOASTERCONFIG;
    applicationType: number;
    userType: Array<any> = UserType();
    application_list: any = [];
    application_id: any = undefined;
    array_list: any = [];
    processStatus = false;
    deleteForm: FormGroup;
    headerHiding = false;
    process = false;
    name_length: any;
    menus: any;
    loading = true;
    searchEnable = false;
    application_type = 0;
    application_name: string;
    indicators: any;
    first_time = true;
    urls: Array<any> = [];
    isSuperUser: Boolean = false;
    listEmit: string[] = [
        'configuration',
        'infrastructure',
        'deploy',
        'dependency',
        'docker-image',
        'kube-events',
        'credentials'
    ];


    @Output('onHelp') onHelp: EventEmitter<any> = new EventEmitter();
    @ViewChild('dropdown') list_dropdown: any;
    @ViewChild('application_dialog_header') application_dialog_header: any;
    @ViewChild('helpbar') helpbar: any;
    @ViewChild('appDataTable') appDataTable: any;
    @ViewChild('customeHeader') customeHeader: any;
    @Output('sidebar_heading') sidebar_heading: EventEmitter<any> = new EventEmitter();
    @Output('notificationEmit') notificationEmit: EventEmitter<any> = new EventEmitter();
    @ViewChild('profile_dialog_header') profile_dialog_header: any;

    isNavSearchVisible: boolean;
    @ViewChild('fsbutton') fsbutton;  // the fullscreen button

    appPreferences: AppPreferences = new AppPreferences();
    app_data;
    default_navigation = true;
    tab: any = [];
    activeUrl: any;
    company_name: any;
    addTaskValue: string = "";
    app_search_list: any;
    application_created = { show: false, timeout: null };
    isNewTicketAvailable = false;

    @Input('navigation')
    set set_navigation(data) {
        this.default_navigation = data;
    }

    @Input('headerHide')
    set headerHide(data: boolean) {
        this.headerHiding = data;
    }

    @HostListener('document:click', ['$event'])
    closeDrop(event) {
        const nameArray: Array<string> = [
            'form-control search-option-drop',
            'drop-down',
            'icon-click-show',
            'fa fa-angle-down',
            'fa fa-star star',
            'fa fa-star star checked'
        ];
        const className = nameArray.includes(event.target.className);
        if (this.router.url != '/admin/users' && this.list_dropdown && this.list_dropdown.isOpen && !className && this.router.url != '/admin/customer') {
            this.list_dropdown.hide();
        }
    }
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public menu: MenuService,
        public userblockService: UserblockService,
        public settingsService: SettingsService,
        public authService: AuthService,
        private branchService: ProjectBranchService,
        private appService: ApplicationService,
        private notificationService: NotificationService,
        public pubnub: PubNubAngular,
        private applicationService: ApplicationService,
        private userservice: UserService,
        private toasterService: ToasterService,
        private fb: FormBuilder,
        private navigateService: NavigationControlService
    ) {
        super();

        // get menu items
        this.menuItems = menu.getMenu();
        this.route.parent.params.subscribe((Mainparams: Params) => {
            if ('id' in Mainparams) {
                this.application_id = Mainparams['id'];
            }
            this.route.params.subscribe((params: Params) => {
                if ('id' in params) {
                    this.application_id = params['id'];
                }
                if ('branch_id' in params) {
                    this.branch_id = params['branch_id'];
                }
            });
        });
        this.settingsService.user$.subscribe(settings => {
            this.isSuperUser = settings['isSuperUser'];
            this.company_name = settings['companyName'];
            if (settings.firstName) {
                this.user = settings.firstName + ' ' + settings.lastName;
            }
        });

        this.paramsHandle();
        this.router.events
            .filter(event => event instanceof NavigationEnd)
            .do(event => event)
            .subscribe((event: NavigationEnd) => {
                timer(1000).subscribe(res => {
                    this.paramsHandle(true, event);
                });
            });

        this.navigateService.getApplicationList.subscribe(res => {
            if (res) {
                this.getApplicationsList(true);
                this.navigateService.applicationAdded(false);
            }
        });

        this.navigateService.getToolTip.subscribe(res => {
            if (res) {
                if (this.application_created.timeout) {
                    clearTimeout(this.application_created.timeout);
                }
                this.application_created.show = true;
                this.application_created.timeout = setTimeout(() => {
                    this.application_created.show = false;
                }, 5000);
                this.navigateService.showToolTip(false);
            }
        })

    }

    ngOnInit() {
        this.getApplicationsList();
        this.deleteForm = this.fb.group({
            'delete': ['', [Validators.required, Validators.pattern(/^([dD](elete|ELETE))$/)]]
        });

        this.pubnub.init({
            publishKey: pubnubKey['publishKey'],
            subscribeKey: pubnubKey['subscribeKey'],
        });        
        this.pubunub_notify();
        this.get_notification();

        this.subscriptions['appPreferences'] = this.settingsService.appPreferences$.subscribe(settings => {
            this.appPreferences = settings || new AppPreferences();

        });

        this.isNavSearchVisible = false;
        if (browser.msie) { // Not supported under IE
            this.fsbutton.nativeElement.style.display = 'none';
        }
    }


    public clickHelp(): void {
        let help = true;
        this.helpBool = true;
        let temp = { 'flag': this.helpBool.toString(), 'page': '' }
        window.localStorage.setItem("helpEnable", JSON.stringify(temp));
        this.onHelp.emit(this.helpBool);
    }

    renameHeading(event) { // sidebar heading change on branch select
        this.sidebar_heading.emit({ name: this.current_project + ' / ' + event.branch_name, branch_id: event.id });
    }

    selected(event, app_item, type: boolean) {
        event.preventDefault();
        event.stopPropagation();
        this.applicationService.addOrRemoveAppBookmark({ app_id: app_item.id }).subscribe(res => {
            const index = this.app_search_list.findIndex(x => !!x.bookmark);
            if (index != -1) {
                this.app_search_list[index].bookmark = !this.app_search_list[index].bookmark;
            }
            const new_index = this.app_search_list.findIndex(x => x.id === app_item.id);
            this.app_search_list[new_index].bookmark = type;
        });
    }

    toggleUserBlock(event) {
        event.preventDefault();
        this.userblockService.toggleVisibility();
    }

    openNavSearch(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setNavSearchVisible(true);
    }

    setNavSearchVisible(stat: boolean) {
        this.isNavSearchVisible = stat;
    }

    getNavSearchVisible() {
        return this.isNavSearchVisible;
    }

    toggleCollapsedSideabar() {
        this.appPreferences['isCollapsed'] = !this.appPreferences['isCollapsed'];
        this.appPreferences['horizontal'] = !this.appPreferences['horizontal'];     // devops-custom change (extra line)
    }

    isCollapsedText() {
        return this.appPreferences['isCollapsedText'];
    }

    toggleFullScreen(event) {
        if (screenfull.enabled) {
            screenfull.toggle();
        }
        // Switch icon indicator
        const el = $(this.fsbutton.nativeElement);
        if (screenfull.isFullscreen) {
            el.children('em').removigveClass('fa-expand').addClass('fa-compress');
        } else {
            el.children('em').removeClass('fa-compress').addClass('fa-expand');
        }
    }

    // Logout User
    logout() {
        const intervel = setInterval(() => {
            try {
                var all = $(".modal-backdrop").map(function () {
                    return this.innerHTML;
                }).get();

                if (all.length > 0) {
                    jQuery('.modal-backdrop').remove();
                    clearInterval(intervel);
                }
                var allmodal = $(".modal").map(function () {
                    return this.innerHTML;
                }).get();

                if (allmodal.length > 0) {
                    jQuery('.modal').remove();
                    clearInterval(intervel);
                }
            } catch (error) {
                console.warn(error);
            }
        }, 4);
        setTimeout(() => {
            if (intervel) {
                clearInterval(intervel);
            }
        }, 3000);
        this.navigateService.navigateSet(null);
        let temp = { 'flag': 'false', 'page': '' }
        window.localStorage.setItem("helpEnable", JSON.stringify(temp));

        this.authService.logout();
    }

    get_notification() {
        this.notificationService.get_notification_list()
            .subscribe(resp => {
                this.notification_obj = resp['records'];
                this.unread_notification = resp['totalRecords'];
                this.show_notify = true;
                this.notificationEmit.emit(true);
            },
                error => {
                    console.log(error);
                });
    }

    pubunub_notify() {
        this.settingsService.user$.subscribe(settings => {
            if (settings.isSuperUser) {
                this.user_type = 'Admin';
            } else {
                this.user_type = 'Users';
            }
            this.user = settings.username;
            let user_id = settings.id;
            { // header user details -- custom
                this.user_details = settings;
                if (!this.isSuperUser && user_id) {
                    this.userservice.getCompanyData(user_id).subscribe(resp => {
                        if(resp['company_logo'] != null){
                            this.company_logo = GetApiurl(resp['company_logo'].substr(1)) + "?" + Math.floor(Math.random() * 100) + 1;
                        }
                    });
                }
                if (this.user_details['profile_image'] != null) {
                    this.profile_image = GetApiurl(this.user_details['profile_image'].substr(1)) + "?" + Math.floor(Math.random() * 100) + 1;
                }
            }
        });
        const channel = this.user;
        this.pubnub.subscribe({ channels: [channel], triggerEvents: true, withPresence: true });
        this.pubnub.getMessage(channel, (msg) => {
            this.get_notification();
        });
    }

    onRead_notify(data) {
        let url = '';
        if (data['notification_type'] === 'Ticket') {
                url = `/applications/${data['application_id']}/support`;
            }
        this.notificationEmit.emit(data['notification_type']);
        this.router.navigate([url]);
        this.notificationService.update_notification(data)
            .subscribe(resp => {
                this.notification_obj = resp['records'];
                this.unread_notification = resp['totalRecords'];
            });
    }

    more_notifications() {
        this.router.navigate(['/notifications']);
    }

    profile_page() {
        this.profile_dialog_header.open_profile_modal();
    }

    ReadAll() {
        this.notificationService.read_all_notification().subscribe(
            (res: object[]) => {
                this.pubunub_notify();
                this.get_notification();
                this.notificationEmit.emit('Read all');
            },
            error => {
                console.log(error);
            }
        );
    }

    navigateToHome() {
        if (this.isSuperUser) this.router.navigate(["/admin/customer"]);
        else
            this.authService.redirectToHome();
    }

    checkImages() {
        this.applicationService.check_registry_details(this.app_id).subscribe(
            (res) => {
                if (res) {
                    this.router.navigate(['/applications/' + this.app_id + '/dependency']);
                } else {
                    this.toasterService.pop(<Toast>{
                        type: 'info',
                        title: 'Configuration is not finished. Click configuration button to configure'
                    });
                }
            }
        );
    }


    animationHandle() {
        const listEmit: string[] = this.listEmit;
        const check = listEmit.filter(e => this.urls.includes(e)).length > 0;
        if (this.urls[2] == "pipeline" && this.urls[3] == "runs") {
            this.tab = this.urls[3];
        }
        else {
            this.tab = this.urls[2];
        }
        if (!check) {
            setTimeout(() => {
                if (this.first_time || !this.menus) {
                    // this.menus = $('#middle');
                    // tslint:disable-next-line:max-line-length
                    // this.indicators = $('<span class="indicator"></span>');
                    // this.menus.append(this.indicators);
                    // this.position_indicator(this.indicators, this.menus.find('li.header-active'));
                    // this.first_time = false;
                    // setTimeout(function() {this.indicators.css('opacity', 1); }, 500);
                } else {
                    // this.position_indicator(this.indicators, this.menus.find('li.header-active'));
                    // setTimeout(function() {this.indicators.css('opacity', 1); }, 500);
                }
                // this.position_indicator(this.indicators, this.menus.find('li.header-active'));
            }, 50);
        }
    }
    settingActive() {
        const listEmit: string[] = this.listEmit;
        return listEmit.filter(e => this.urls.includes(e)).length > 0;
    }

    removeSpan() {
        $('.indicators').remove();
        this.menu = undefined;
    }

    position_indicator(indicators, ele) {
        try {
            const left = ele.offset().left + 15;
            const width = ele.width();
            indicators.stop().animate({
                left: left,
                width: width,
            });
        } catch (e) {
            console.log(e);
        }
    }

    paramsHandle(flag = null, event = null) {
        this.route.children.forEach(ele => {
            ele.params.subscribe((params: Params) => {
                this.application_id = params['id'];
            });
        });
        this.route.parent.params.subscribe(params => {
            if ('id' in params) {
                this.application_id = params['id'];
            }
            this.route.params.subscribe(subParams => {
                if ('id' in subParams) {
                    this.application_id = subParams['id'];
                }
                if (flag) {
                    this.urls = event.url.split(/\W+/);
                    this.urls.splice(1, 1);
                    this.animationHandle();
                    if (this.urls.includes('runs')) {
                        const rmIndex = this.urls.indexOf('pipeline');
                        if (rmIndex > -1) {
                            this.urls.splice(rmIndex, 1);
                            this.animationHandle();
                        }
                    }
                } else {
                    this.urls = this.router.url.split(/\W+/);
                    this.urls.splice(1, 1);
                    this.animationHandle();
                    if (this.urls.includes('runs')) {
                        const rmIndex = this.urls.indexOf('pipeline');
                        if (rmIndex > -1) {
                            this.urls.splice(rmIndex, 1);
                            this.animationHandle();
                        }
                    }
                }
                this.navigateService.setApplicationId(this.application_id);
            });
        });
    }

    deleteApplication(value: boolean) {
        if (value) {
            this.processStatus = true;
            this.applicationService.deleteApplication(this.application_id)
                .subscribe(resp => {
                    this.toasterService.pop(<Toast>{
                        type: 'success',
                        title: 'Application Deleted',
                    });
                    this.processStatus = false;
                    $('#del_app_new').modal('hide');
                    this.deleteForm.controls.delete.reset('');
                    this.navigateService.applicationAdded(true);
                    if (resp['id']) {
                        this.router.navigate([`/applications/${resp['id']}/dashboard`]).then(res => res);
                    } else {
                        this.router.navigate([`/applications/dashboard`]).then(res => res);
                    }
                },
                    error => {
                        console.error(error);
                        this.processStatus = false;
                        $('#del_app_new').modal('hide');
                        this.deleteForm.controls.delete.reset('');
                        this.toasterService.pop(<Toast>{
                            type: 'error',
                            title: 'Failed to Delete Application',
                        });
                    }
                );
        } else {
            $('#del_app_new').modal('hide');
            this.deleteForm.controls.delete.reset('');
        }
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    getApplicationsList(latest = null) {
        this.addTaskValue = ' ';
        this.addTaskValue = null;
        this.applicationService.listApplication({}).subscribe(resp => {
            this.application_list = resp['records'];
            this.app_search_list = resp['records'];
            if (!this.application_id || latest) {
                if (this.application_list.length) {
                    let selectedApp = this.application_list;
                    if (latest) {
                        selectedApp = this.application_list.sort(this.dynamicSort('-id'));
                    }
                    this.application_id = selectedApp[0]['id'];
                    this.application_name = selectedApp[0]['application_name'];
                    this.name_length = this.application_name.length;
                    this.application_type = selectedApp[0]['application_type'];
                    this.animationHandle();
                } else {
                    this.application_name = ' Add application ';
                    this.name_length = this.application_name.length;
                    this.application_id = 0;
                    this.animationHandle();
                }
            } else {
                this.navigateService.navigateSet(true);
                const app = this.application_list.filter(x => x['id'] === this.application_id);
                if (app.length > 0) {
                    this.application_id = app[0]['id'];
                    this.application_name = app[0]['application_name'];
                    this.name_length = this.application_name.length;
                    this.application_type = app[0]['application_type'];
                    this.animationHandle();
                } else {
                    this.applicationService.get_application_name(this.application_id).subscribe(
                        (res: any) => {
                            this.application_name = res ? res : ' Application ';
                            this.name_length = this.application_name.length;
                            this.animationHandle();
                        },
                        error => {
                            console.error(error);
                            this.application_name = 'Select Application ';
                        }
                    );
                }
            }
            this.navigateService.defaultNavigate.subscribe(data => {
                if (this.default_navigation && !data) {
                    this.urls = ['dashboard'];
                    this.tab = this.urls;
                    this.animationHandle();
                    if (this.application_id !== 0) {
                        this.router.navigate([`/applications/${this.application_id}/dashboard/`]).then(res => res);
                    } else {
                        this.router.navigate([`/applications/dashboard/`]).then(res => res);
                    }
                    setTimeout(() => {
                        this.navigateService.navigateSet(true);
                    }, 1500);
                }
            });
            this.loading = false;
        });

    }

    navigate(key: any) {
        $('.navbar-collapse').collapse('hide');
        switch (key) {
            case 'dashboard':
                this.urls = ['dashboard'];
                this.tab = this.urls;
                this.navigation(`/applications/${this.application_id}/dashboard/`);
                this.animationHandle();
                break;
            case 'list':
                this.urls = ['projects'];
                this.tab = this.urls;
                this.navigation(`/applications/${this.application_id}/projects/list/`);
                this.animationHandle();
                break;
            case 'delete':
                $('#del_app_new').modal('show');
                break;
            case 'configuration':
                this.urls = ['configuration'];
                this.tab = this.urls;
                this.navigation(`/applications/${this.application_id}/configuration/type/`);
                this.removeSpan();
                break;
            case 'imagecreation':
                this.urls = ['dependency'];
                this.tab = this.urls;
                this.navigation(`/applications/${this.application_id}/dependency/`);
                this.removeSpan();
                break;
            case 'deploy':
                this.urls = ['deploy'];
                this.tab = this.urls;
                if (this.application_type === 1) {
                    this.navigation(`/applications/${this.application_id}/deploy/`);
                    this.removeSpan();
                } else {
                    this.navigation(`/applications/${this.application_id}/deploy/deploy-baremetal/`);
                    this.removeSpan();
                }
                break;
            case 'edit':
                this.urls = ['applications', 'edit'];
                this.tab = this.urls;
                this.navigation(`/applications/${this.application_id}/edit/`);
                this.animationHandle();
                break;
            case 'create':
                this.application_dialog_header.open_modal();
                break;
            case 'infrastructure':
                this.urls = ['infrastructure'];
                this.tab = this.urls;
                this.navigation(`/applications/${this.application_id}/infrastructure/`);
                this.removeSpan();
                break;
            case 'pipeline':
                this.urls = ['pipeline'];
                this.tab = this.urls;
                this.navigation(`/applications/${this.application_id}/pipeline/list/`);
                this.animationHandle();
                break;
            case 'pipeline-runs':
                this.urls = ['pipeline-runs'];
                this.tab = this.urls;
                this.navigation(`/applications/${this.application_id}/pipeline/pipeline-runs/`);
                this.animationHandle();
                break;
            case 'monitoring':
                this.urls = ['monitoring'];
                this.tab = this.urls;
                this.navigation(`/applications/${this.application_id}/monitoring/aws`);
                this.animationHandle();
                break;
            case 'reports':
                this.urls = ['reports'];
                this.tab = this.urls;
                this.navigation(`/applications/${this.application_id}/reports/`);
                this.animationHandle();
                break;
            case 'market-place':
                this.urls = ['market-place'];
                this.tab = this.urls;
                this.navigation(`/applications/${this.application_id}/market-place/`);
                this.animationHandle();
                break;
            case 'support':
                this.urls = ['support'];
                this.tab = this.urls;
                this.navigation(`/applications/${this.application_id}/support/`);
                this.animationHandle();
                break;
            case 'settings':
                if (!this.urls.includes('settings')) {
                    this.urls = ['settings'];
                    this.tab = this.urls;
                    this.navigation(`/applications/${this.application_id}/settings/credentials/image-config/`);
                    this.animationHandle();
                }
                break;
            case 'credentials':
                this.urls = ['credentials'];
                this.tab = this.urls;
                this.navigation(`/applications/${this.application_id}/credentials/`);
                this.removeSpan();
                break;
            case 'actions':
                this.urls = ['actions'];
                this.tab = this.urls;
                this.navigation(`/applications/${this.application_id}/actions/list/`)
                this.animationHandle();
                break;
            case 'marketplace':
                this.urls = ['marketplace'];
                this.tab = this.urls;
                this.navigation(`/applications/${this.application_id}/market-place/`)
                this.animationHandle();
                break;
            case '/':
                this.urls = [''];
                this.navigation(`/`);
                this.animationHandle();
                break;
            default:
                this.urls = [];
                this.tab = this.urls;
                this.animationHandle();
                break;
        }
    }

    navigation(flag) {
        this.router.navigate([flag]).then(res => res);
    }

    change(item) {
        this.urls = ['dashboard'];
        this.tab = this.urls;
        this.animationHandle();
        this.list_dropdown.hide();
        this.application_id = item['id'];
        this.application_name = item['application_name'];
        this.name_length = this.application_name.length;
        this.application_type = item['application_type'];
        $('#inputSuccess2').val('');
        this.router.navigate([`/applications/${item['id']}/dashboard`]).then(res => res);
    }

    filterOption(event) {
        this.searchEnable = true;
        const data = { data: event.target.value };
        this.addTaskValue = event.target.value;
        this.applicationService.listApplication(data).subscribe(
            (res: object[]) => {
                this.app_search_list = res['records'];
                this.searchEnable = false;
            });
    }

    getModal(event) {
        if (event && this.tab) {
            this.urls = this.tab;
        }
    }
}
