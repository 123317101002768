import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//--
import {
  InArrayPipe,
  InStringPipe,
  InCommaSeperatedPipe,
  RemoveFileExtension,
  ArrayNameSplit,
  EscapeHtmlPipe
} from './array.pipes';
import { ClickOutsideDirective } from './click-outside.directive';
import { NormalColumn } from './normal-column.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    InStringPipe,
    InCommaSeperatedPipe,
    InArrayPipe,
    ClickOutsideDirective,
    RemoveFileExtension,
    ArrayNameSplit,
    NormalColumn,
    EscapeHtmlPipe
  ],
  exports: [
    InStringPipe,
    InCommaSeperatedPipe,
    InArrayPipe,
    ClickOutsideDirective,
    RemoveFileExtension,
    ArrayNameSplit,
    NormalColumn,
    EscapeHtmlPipe
  ]
})
export class PipesModule { }
