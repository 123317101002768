import {
    NgModule,
    ModuleWithProviders
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    FormsModule,
    ReactiveFormsModule
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ToasterModule } from 'angular2-toaster/angular2-toaster';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { DatepickerModule } from 'ngx-bootstrap/datepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SelectModule } from 'ng2-select';
import { McBreadcrumbsModule } from 'ngx-breadcrumbs';
import { NKDatetimeModule } from 'ng2-datetime/ng2-datetime';
import { Ng2TableModule } from 'ng2-table/ng2-table';
import { MatDatepickerModule, MatNativeDateModule } from '@angular/material';
import { HighchartsStatic } from 'angular2-highcharts/dist/HighchartsService';
import { CronEditorModule } from 'cron-editor';
import { MatStepperModule } from '@angular/material';
import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor';

// --
import {
    FlotDirective,
    SparklineDirective,
    EasypiechartDirective,
    CheckallDirective,
    VectormapDirective,
    NowDirective,
    ScrollableDirective,
    JqcloudDirective,
    ControlMessagesComponent,
    AppDatatableComponent,
    AppCheckboxComponent,
    AppAutocomplete,
    Ng2TableExtendedComponent,
    WizardViewComponent,
    PipelinesComponent,
    StatusIconComponent,
    ApplicationPipelinesComponent,
    ApplicationWizardComponent,
    AccessDirective,
    DeniedDirective
} from '@app/shared/directives';
import {
    PipesModule
} from '@app/shared/pipes';
import { ColorsService } from '@app/shared/colors';
import { ServicesModule } from '@app/services';
import { ElementsModule } from '@app/elements';
import { ChartModule } from 'angular2-highcharts';
import { PubNubAngular } from 'pubnub-angular2';
import { CustomBreadcrumbComponent } from './directives/custom-breadcrumb/custom-breadcrumb.component';
import { CustomHelpIconComponent } from './directives/custom-help-icon/custom-help-icon.component';
import { CustomDevopsHeaderComponent } from './directives/custom-devops-header/custom-devops-header.component';
import { CustomDatatableComponent } from './directives/custom-datatable/custom-datatable.component';
import { ApplicationsCreateNewComponent } from './directives/applications-create-new/applications-create-new.component';
import { CommonDevopsPipelineComponent } from './directives/common-devops-pipeline/common-devops-pipeline.component';
import { UserProfileComponent } from './../features/home/user-profile/user-profile.component';
import { DynamicActionJsonComponent } from '@app/features/home/pipeline/pipeline/workflow-setup/action/dynamic-action-json/dynamic-action-json.component';
import { RlTagInputModule } from 'angular2-tag-input';
import { ActionListComponent } from '@app/features/home/admin/actions/action-list/action-list.component';
import { InputFormEditorComponent } from '@app/features/home/admin/actions/input-form-editor/input-form-editor.component';
import { ActionsEditorComponent } from '@app/features/home/admin/actions/actions-editor/actions-editor.component';
import { ActionsComponent } from '@app/features/home/admin/actions/actions.component';
import { MatIconModule } from '@angular/material/icon';

const monacoConfig: NgxMonacoEditorConfig = {
    baseUrl: '', // configure base path for monaco editor
    defaultOptions: { scrollBeyondLastLine: false }, // pass default options to be used
    onMonacoLoad: () => { console.log((<any>window).monaco); }
    // here monaco object will be available as window.monaco use this function to extend monaco editor functionality.
};

// https://angular.io/styleguide#!#04-10

export function highchartsFactory() {
    var hc = require('highcharts');
    var hc3d = require('highcharts/highcharts-3d');
    var hcm = require('highcharts/highcharts-more');
    var hcg = require('highcharts/modules/solid-gauge');
    var hcd = require('highcharts/modules/drilldown');
    hcm(hc);
    hc3d(hc);
    hcg(hc);
    hcd(hc);
    return hc;
}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
        TranslateModule,
        AccordionModule.forRoot(),
        AlertModule.forRoot(),
        ButtonsModule.forRoot(),
        CarouselModule.forRoot(),
        CollapseModule.forRoot(),
        DatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        PaginationModule.forRoot(),
        ProgressbarModule.forRoot(),
        RatingModule.forRoot(),
        TabsModule.forRoot(),
        TimepickerModule.forRoot(),
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        TypeaheadModule.forRoot(),
        ToasterModule.forChild(),
        NgxDatatableModule,
        SelectModule,
        McBreadcrumbsModule.forRoot(),
        NKDatetimeModule,
        Ng2TableModule,
        MatDatepickerModule,
        MatNativeDateModule,
        //-
        ServicesModule,
        PipesModule,
        ElementsModule,
        ChartModule,
        RouterModule,
        CronEditorModule,
        MatStepperModule,
        MonacoEditorModule.forRoot(monacoConfig),
        RlTagInputModule,
        MatIconModule
    ],
    providers: [
        ColorsService,
        {
            provide: HighchartsStatic,
            useFactory: highchartsFactory
        },
        PubNubAngular,
    ],
    declarations: [
        FlotDirective,
        SparklineDirective,
        EasypiechartDirective,
        CheckallDirective,
        VectormapDirective,
        NowDirective,
        ScrollableDirective,
        JqcloudDirective,
        //--
        ControlMessagesComponent,
        AppDatatableComponent,
        AppCheckboxComponent,
        AppAutocomplete,
        Ng2TableExtendedComponent,
        WizardViewComponent,
        PipelinesComponent,
        StatusIconComponent,
        ApplicationPipelinesComponent,
        ApplicationWizardComponent,
        CustomBreadcrumbComponent,
        CustomHelpIconComponent,
        AccessDirective,
        DeniedDirective,
        CustomDevopsHeaderComponent,
        CustomDatatableComponent,
        ApplicationsCreateNewComponent,
        CommonDevopsPipelineComponent,
        UserProfileComponent,
        DynamicActionJsonComponent,
        ActionListComponent,
        InputFormEditorComponent,
        ActionsEditorComponent,
        ActionsComponent,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        RouterModule,
        AccordionModule,
        AlertModule,
        ButtonsModule,
        CarouselModule,
        CollapseModule,
        DatepickerModule,
        BsDropdownModule,
        ModalModule,
        PaginationModule,
        ProgressbarModule,
        RatingModule,
        TabsModule,
        TimepickerModule,
        TooltipModule,
        PopoverModule,
        TypeaheadModule,
        ToasterModule,
        FlotDirective,
        SparklineDirective,
        EasypiechartDirective,
        CheckallDirective,
        VectormapDirective,
        NowDirective,
        ScrollableDirective,
        JqcloudDirective,
        NgxDatatableModule,
        SelectModule,
        McBreadcrumbsModule,
        NKDatetimeModule,
        Ng2TableModule,
        MatDatepickerModule,
        MatNativeDateModule,
        //-
        ServicesModule,
        ControlMessagesComponent,
        AppDatatableComponent,
        AppCheckboxComponent,
        AppAutocomplete,
        PipesModule,
        ElementsModule,
        ChartModule,
        WizardViewComponent,
        PipelinesComponent,
        StatusIconComponent,
        ApplicationPipelinesComponent,
        ApplicationWizardComponent,
        CustomBreadcrumbComponent,
        CronEditorModule,
        CustomHelpIconComponent,
        AccessDirective,
        DeniedDirective,
        CustomDevopsHeaderComponent,
        CustomDatatableComponent,
        ApplicationsCreateNewComponent,
        MatStepperModule,
        CommonDevopsPipelineComponent,
        UserProfileComponent,
        MonacoEditorModule,
        DynamicActionJsonComponent,
        RlTagInputModule,
        ActionListComponent,
        InputFormEditorComponent,
        ActionsEditorComponent,
        ActionsComponent,
        MatIconModule
    ]
})

// https://github.com/ocombe/ng2-translate/issues/209
export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule
        };
    }
}
