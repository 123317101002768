export const isObjectsEqual = (obj1: object, obj2: object): boolean =>
  JSON.stringify(obj1) === JSON.stringify(obj2);

export const isNullOrUndefined = (value:any) => {
    return value==null; // if null or undefined returns true
}
export const sortObject = (obj:any): object =>  {
  return obj.sort((a,b) => a.index - b.index).map((sortedObj, index, array) => sortedObj)
}

/**
 * 
 * @param formData formdata instance
 * @param data any valid js object
 * @param parentKey 
 */
export const  buildFormData = (formData:any, data:any, parentKey?:any) => {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
}

/**
 * 
 * @param data any valid js object
 * @returns converted formData
 */
export const jsonToFormData = (data: any) => {
  const formData = new FormData();
  
  buildFormData(formData, data);
  
  return formData;
}