import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from "@angular/common/http";
import { InterfaceService } from '@app/interfaces';
import { map, catchError, tap } from 'rxjs/operators';
import { Observable } from "rxjs";
import { authTokenId, GetApiurl } from '@app/core';

@Injectable({
  providedIn: 'root'
})
export class SupportService extends InterfaceService {
  constructor(private http: HttpClient) {
    super();
  }

  support_list(status) {
    return this.http.get(this.getApiUrl(`tickets/`, { 'status': status }), this.getHttpOptions('json'))
      .pipe(
        tap(Response => Response),
        catchError(this.handleError)
      );
  }

  newTicketCreate(data: any) {
    return this.http.post(this.getApiUrl(`tickets/`), JSON.stringify(data), this.getHttpOptions('json'))
      .pipe(
        tap(Response => Response),
        catchError(this.handleError)
      );
  }

  uploadTicketFile(file: File, index: number, ticketId: number): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('file_index', index.toString());
    formData.append('ticketId', ticketId.toString());

    return this.http.post(this.getApiUrl(`tickets/upload/`), formData, {
      reportProgress: true,
      observe: 'events',
      headers: { 'Authorization': `JWT ${localStorage.getItem(authTokenId)}` }
    });
  }

  getTicketDetails(id: any) {
    return this.http.get(this.getApiUrl(`tickets/${id}`), this.getHttpOptions('json'))
      .pipe(
        tap(Response => Response),
        catchError(this.handleError)
      );
  }

  addNewComment(payload: any) {
    return this.http.post(this.getApiUrl(`tickets/add-comments/`), JSON.stringify(payload), this.getHttpOptions('json'))
      .pipe(
        tap(Response => Response),
        catchError(this.handleError)
      );
  }

  changeTicketStatus(payload: any) {
    return this.http.post(this.getApiUrl(`tickets/status-change/`), JSON.stringify(payload), this.getHttpOptions('json'))
      .pipe(
        tap(Response => Response),
        catchError(this.handleError)
      );
  }
}
