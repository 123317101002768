import { Component, OnInit, ViewChild, AfterViewInit, DoCheck } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToasterConfig } from 'angular2-toaster';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit,AfterViewInit, DoCheck {


    helpBar:boolean=false
    toasterConfig: ToasterConfig = new ToasterConfig({
        positionClass: 'toast-top-center',
        animation: 'fade',
        newestOnTop: true,
        timeout: 5000,
        showCloseButton: true
    });
    currenturl: string;
    isSystemDashboardClicked = true;

    @ViewChild('appHeader') appHeader: any;
    @ViewChild('appSidebar') appSidebar: any;

    constructor(
        private route: ActivatedRoute,
        private router: Router
    ) { }
    ngAfterViewInit(): void {
        let temp=JSON.parse(window.localStorage.getItem("helpEnable"))                   
        if(temp!=null &&  'true'==temp.flag){
            this.helpBar=true;

        }
    }

    ngOnInit() {
        this.appHeader.enableAppList = true;
        this.currenturl = window.location.href;
        this.isSystemDashboardClicked = this.route.snapshot.queryParamMap.get('isSystem') === 'true'
    }

    ngDoCheck() {
        this.currenturl = window.location.href;
        
    }
    sidebarHeading(event) { // sidebar heading change on click event(header list)
        this.appSidebar.menuItems.forEach((item) => {
            if (item['heading']) {
                item['text'] = event.name;
            }

            if ("submenu" in item) {
                item.submenu.forEach((subitem) => {
                    subitem['link'] = subitem['link'].replace(/[0-9]+/g, event.branch_id)
                })
            }
            else if ("link" in item) {
                item['link'] = item['link'].replace(/[0-9]+/g, event.branch_id)
            }
        })
    }


    test($event){
        this.helpBar=$event;
    }

    close(){
        this.helpBar=false;
        let temp=JSON.parse(window.localStorage.getItem("helpEnable"))
        temp.flag='false'

        window.localStorage.setItem("helpEnable", JSON.stringify(temp));
    }

    toggleDashboards (dashboardName?:string) {
        if (dashboardName  == 'system') {
            this.isSystemDashboardClicked = true;

            this.router.navigate([],{
                queryParams:{isSystem: true}
            })
        }
        else {
            this.isSystemDashboardClicked = false;

            this.router.navigate([],{
                queryParams:{isSystem: false}
            })
        }
    }
}
